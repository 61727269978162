@font-face {
  font-family: "GT-Planar-Regular";
  src: url(fonts/GT-Planar-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "GT Ultra Median";
  src: url(fonts/GT-Ultra-Median-Light.woff);
}

.ContentContainer {
  display: grid;
  grid-template-columns: 400px auto;
  height: 85vh;
  padding-top: 20px;
}

.LeftContentContainer {
  grid-column: 1;
  grid-row: 1;
}

.RightContentContainer {
  grid-column: 2;
  grid-row: 1;
  background-color: "#261B1B";
  margin-left: 2em;
}

#ccp,
#wisdom-container,
#customerprofiles-container,
#cases-container,
#customviews-container {
  height: 90%;
}

.Title {
  font-family: "GT Ultra Median" !important;
  font-size: 40px;
  color: #b5f602;
  font-weight: 300;
  line-height: 50px;
  margin-left: 10px;
  align-self: revert;
}

.TitleContainer {
  align-items: center;
  align-self: center;
  align-content: center;
  display: contents;
}

img.logo {
  max-height: 40px !important;
  align-items: center;
}

.login {
  /* --login-signed-out-button-background: red;
  --login-signed-out-button-hover-background: orange;
  --login-signed-out-button-text-color: purple;
  --login-signed-in-background: red;
  --login-signed-in-hover-background: green;
  --login-button-padding: 5px;
  --login-popup-background-color: blue;
  --login-popup-text-color: brown;
  --login-popup-command-button-background-color: orange;
  --login-popup-padding: 8px;
  --login-add-account-button-text-color: yellow;
  --login-add-account-button-background-color: red;
  --login-add-account-button-hover-background-color: purple;
  --login-command-button-background-color: orange;
  --login-command-button-hover-background-color: purple;
  --login-command-button-text-color: black; */

  --login-signed-in-hover-background: silver;

  /** person component tokens **/
  --person-line1-text-color: whitesmoke;
  --person-line2-text-color: white;
  /* --person-background-color: blue; */
}
